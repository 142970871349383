import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Tessamyn Wieczorkowski | Designer', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Designer',
  name: 'Tessamyn Wieczorkowski',
  subtitle: 'Creating Visual Solutions',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'tessamyn.jpg',
  paragraphOne: 'I am a visual designer from Pittsburgh with a Bachelor of Arts from the Art Institute.',
  paragraphTwo: 'Currently, I am working from home running an online business at lazycaturday.com, but I\'m open for new opportunites in the visual arts field.',
  paragraphThree: ' ',
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'mad-mex.jpg',
    title: 'Mad Mex - Oakland',
    info: 'Selected finishes for panels and quality checked design drawings.',
    info2: '',
    url: 'https://madmex.com/location/oakland/',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'upmc-va.jpg',
    title: 'Pittsburgh VA Medical Center-University Drive',
    info: 'Tasked with selection of fabrics and finishes as well as documenting any changes to space planning via AutoCAD',
    info2: '',
    url: 'https://www.va.gov/pittsburgh-health-care/',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  }
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'tessamyn@lazycaturday.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/tessamyn-wieczorkowski-90056960/',
    }
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
